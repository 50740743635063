#mobile-menu {
    display: block;
	pointer-events: none;
	opacity: 0;
	z-index: -1;
	transform: translateX(100%);
	transform-origin: 50% 0;
	transition: all 0.3s ease;
}

body.mobile-menu-visible {
	overflow: hidden;

	#mobile-menu {
		pointer-events: initial;
		opacity: 1;
		z-index: 30;
		transform: translateX(0);
	}

	#mobile-menu-button {
		.line1 {
			transform: rotate(-45deg) translateY(3px);
		}

		.line2 {
			transform: rotate(45deg) translateY(-3px);
		}
	}

    > header {
        box-shadow: inset 0 -0.0625rem 0 0rem theme('colors.cerulean');
    }
}
