@import "tailwindcss/base";

// import custom styles

@layer base {
    @import 'scss/font-poppins';

    html {
        scroll-behavior: smooth;
    }

    body {
        font-family: 'Poppins', sans-serif;
        @apply leading-relaxed;
        --swiper-theme-color: theme(colors.cerulean);
    }

    .container {
        @apply mx-auto;
        @apply px-4;
    }

    section {
        scroll-margin-top: 2.5rem;
    }
}

@import "scss/mobile-menu";

@import "tailwindcss/components";
@import "tailwindcss/utilities";

.bg-light-bg + .bg-light-bg {
    padding-top: 0 !important;
}

.text-content ul {
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    li {
        list-style: disc outside;
    }
}
